import React, { useState, useMemo, Fragment, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { themeGet } from 'styled-system';
import { addDays } from 'date-fns';
import styled from '@emotion/styled';
import omit from 'lodash/omit';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { Box, Flex, Text } from '@qga/roo-ui/components';
import AppLink from 'components/AppLink';
import { registerGclid } from '../../sessionStorage';
import { updateQuery } from 'store/search/searchActions';
import { getQueryParams } from 'store/router/routerSelectors';
import LocationAutocompleter from 'components/LocationAutocompleter';
import AvailabilityDatePicker from 'components/AvailabilityDatePicker';
import OccupantPicker from 'components/OccupantPicker';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import { useDataLayer } from 'hooks/useDataLayer';
import { InputErrorField, SubmitButton } from './HotelsTab.style';

type SearchQueryProps = {
  location?: string;
  checkIn?: string;
  checkOut?: string;
  adults?: number;
  children?: number;
  infants?: number;
  id?: number;
  isPropertySearch?: boolean;
};

type SearchLocationError = {
  message?: string;
};

type SearchDatesError = {
  message?: string;
};

const labelOptions = {
  color: 'greys.charcoal',
  fontWeight: 'bold',
  fontSize: 'sm',
  lineHeight: 1.25,
  mb: 2,
  style: { fontFamily: 'Jetstar' },
};

const mapQueryToGAPayload = (query) =>
  Object.keys(query)
    .map((inputType) => {
      switch (inputType) {
        case 'location':
          if (query.isPropertySearch) {
            return { type: 'Property Search', value: `${query.propertyName} Selected` };
          } else {
            return { type: 'Location Search', value: `${query.location} Selected` };
          }
        case 'checkIn':
          return { type: 'Date Calendar', value: 'Checkin Date Selected' };
        case 'checkOut':
          return { type: 'Date Calendar', value: 'Checkout Date Selected' };
        case 'adults':
          return { type: 'Guests Dropdown', value: `Guests Adults ${query.adults} Selected` };
        case 'children':
          return { type: 'Guests Dropdown', value: `Guests Children ${query.children} Selected` };
        case 'infants':
          return { type: 'Guests Dropdown', value: `Guests Infants ${query.infants} Selected` };
        case 'payWith':
          return { type: 'Points and Cash Toggle', value: `${capitalize(query.payWith)} Selected` };
        default:
          return null;
      }
    })
    .filter(Boolean);

const StyledAppLink = styled(AppLink)`
  font-size: ${themeGet('fontSizes.sm')} !important;
  line-height: 1.5 !important;
  border: none !important;
`;

const HotelsTab = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const gclid = router.query.gclid ?? null;

  useEffect(() => {
    if (gclid) {
      registerGclid(gclid);
    }
  }, [gclid]);

  const { emitInteractionEvent } = useDataLayer();
  const today = new Date();

  const [searchLocationError, setSearchLocationError] = useState<SearchLocationError>({});
  const [searchDatesError, setSearchDatesError] = useState<SearchDatesError>({});

  const query = useSelector(getQueryParams);
  const [searchQuery, setSearchQuery] = useState<SearchQueryProps>({
    checkIn: query.checkIn ? query.checkIn : today,
    checkOut: query.checkOut ? query.checkOut : addDays(today, 1),
  });

  useEffect(() => {
    if (router.isReady) {
      setSearchQuery({
        location: query.location ? query.location : null,
        checkIn: query.checkIn ? query.checkIn : today,
        checkOut: query.checkOut ? query.checkOut : addDays(today, 1),
        adults: query.adults ? Number(query.adults) : 2,
        children: query.children ? Number(query.children) : 0,
        infants: query.infants ? Number(query.infants) : 0,
        id: query.id ? query.id : null,
        isPropertySearch: query.isPropertySearch ? JSON.parse(query.isPropertySearch) : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const { location, checkIn, checkOut, adults, children, infants } = searchQuery;
  const [selectedDates, setSelectedDates] = useState({});
  useEffect(() => {
    if (router.isReady) {
      const checkInDate = checkIn ? new Date(checkIn) : undefined;
      const checkOutDate = checkOut ? new Date(checkOut) : undefined;
      setSelectedDates({ startDate: checkInDate, endDate: checkOutDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const selectedOccupants = useMemo(() => ({ adults, children, infants }), [adults, children, infants]);
  const searchUrl = useMemo(() => {
    const { id: propertyId, isPropertySearch, ...rest } = searchQuery;
    const omitParams = isPropertySearch ? ['location', 'propertyName', 'excludeParams'] : ['propertyName', 'excludeParams'];
    const searchQueryString = stringifyQueryValues(omit(rest, omitParams));
    const searchPath = isPropertySearch ? `/properties/${propertyId}` : '/search/list';

    if (!searchQuery?.location || !searchQuery?.checkIn || !searchQuery?.checkOut) {
      return undefined;
    } else {
      return gclid ? `${searchPath}?${searchQueryString}&gclid=${gclid}` : `${searchPath}?${searchQueryString}`;
    }
  }, [gclid, searchQuery]);

  const clearErrors = (payload) => {
    if (searchLocationError && payload.location) {
      setSearchLocationError({});
    } else if (searchDatesError && payload.checkIn && payload.checkOut) {
      setSearchDatesError({});
    }
  };

  const onUpdateQuery = (payload) => {
    clearErrors(payload);

    setSearchQuery({ ...searchQuery, ...payload });
    const queryEvents = mapQueryToGAPayload(payload);
    queryEvents.forEach((gaPayload) => {
      emitInteractionEvent(gaPayload);
    });
  };

  const onSubmitQuery = () => {
    if (!searchQuery?.location || !searchQuery?.checkIn || !searchQuery?.checkOut) {
      if (!searchQuery?.location) {
        setSearchLocationError({ message: 'Enter a destination or hotel' });
      }
      if (!searchQuery?.checkIn || !searchQuery?.checkOut) {
        setSearchDatesError({ message: 'Please select dates' });
      }
    } else {
      const payload = {
        location: searchQuery.location,
        checkIn: searchQuery.checkIn,
        checkOut: searchQuery.checkOut,
        adults: searchQuery.adults,
        children: searchQuery.children,
        infants: searchQuery.infants,
        id: searchQuery.id,
        isPropertySearch: searchQuery.isPropertySearch,
      };
      dispatch(updateQuery(payload));

      const type = `${searchQuery.isPropertySearch ? 'Property' : 'Location'} Search`;
      emitInteractionEvent({ type, value: 'Successful' });
    }
  };

  const onRouteToLocation = (payload) => {
    clearErrors(payload);
    onUpdateQuery({ ...payload, isPropertySearch: false });
  };

  const onRouteToProperty = (payload) => {
    clearErrors(payload);

    onUpdateQuery({ ...payload, location: payload.propertyName, isPropertySearch: true });
  };

  const onClickOutside = useCallback(() => {
    setSelectedDates({ startDate: undefined, endDate: undefined });
  }, [setSelectedDates]);

  return (
    <Fragment>
      {/* This Heading was added for SEO optimisation purposes and it should be used only in the Homepage */}
      <Text
        fontSize={['base', 'lg']}
        fontWeight="bold"
        lineHeight={['22px', 1.25]}
        display={['block']}
        pb={6}
        style={{ fontFamily: 'Jetstar' }}
      >
        Book Hotels & Accommodation
      </Text>
      <Flex flexWrap="wrap" position="relative">
        <Box pr={[0, 0, 3]} width={['100%', '100%', '35%']} order={1}>
          <LocationAutocompleter
            locationName={location}
            labelOptions={labelOptions}
            updateQuery={onRouteToLocation}
            routeToProperty={onRouteToProperty}
            placeholder="Enter a destination or hotel"
            error={!!searchLocationError?.message}
            title="Destination"
            label="Where would you like to go?"
          />
        </Box>
        {(!isEmpty(searchLocationError) || !isEmpty(searchDatesError)) && (
          <>
            <Box width={['100%', '100%', '35%']} pr={[0, 0, 3]} order={[2, 2, 4]}>
              {searchLocationError?.message && <InputErrorField error={searchLocationError} data-testid="missing-location-error" />}
            </Box>
            <Box width={['100%', '100%', '35%']} pr={[0, 0, 3]} order={[4, 3, 5]}>
              {searchDatesError?.message && <InputErrorField error={searchDatesError} data-testid="dates-missing-error" />}
            </Box>
          </>
        )}
        <Box pr={[0, 0, 3]} pt={[4, 4, 0]} width={['100%', '100%', '35%']} order={[3, 2]} data-testid="stay-date-picker">
          <AvailabilityDatePicker
            selectedDates={selectedDates}
            labelOptions={labelOptions}
            backgroundColor={'white'}
            clearSelectedDates={onClickOutside}
            updateQuery={onUpdateQuery}
            anchorX="right"
            isLimited={true}
            error={!!searchDatesError?.message}
            extraMonthBreakpoint={934}
          />
        </Box>
        <Box pt={[4, 4, 0]} width={['100%', '100%', '30%']} order={[4, 3]} data-testid="occupant-picker">
          <OccupantPicker
            occupants={selectedOccupants}
            labelOptions={labelOptions}
            updateQuery={onUpdateQuery}
            viewThreshold={0}
            verboseInMobile
          />
        </Box>
        <Box width={['100%', '50%', '30%']} mt={6} ml="auto" order={7}>
          <SubmitButton
            as={StyledAppLink}
            to={searchUrl}
            variant="primary"
            onClick={onSubmitQuery}
            aria-label="Search hotels"
            width="100%"
            data-testid="search-hotels-cta"
          >
            Search hotels
          </SubmitButton>
        </Box>
      </Flex>
    </Fragment>
  );
};

export default HotelsTab;
